// @ts-check
import { graphql } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TypographyMarkdown } from "../components/typography"
import Card from "../components/card"

const Wrapper = styled(TypographyMarkdown)`
  max-width: 760px;

  h1:first-of-type {
    margin-top: 0;
  }

  h2 {
    margin-top: 80px;
  }
  h3 {
    margin-top: 60px;
  }
  h4 {
    margin-top: 30px;
  }

  table {
    border-collapse: collapse;
  }
  table,
  th,
  td {
    border: 1px solid;
  }

  th {
    padding: 8px 10px;
    background-color: #eee;
  }
  td {
    padding: 8px 10px;
  }
`

const LegalTemplate = ({ data }) => (
  <Layout bg="white" newsletter={null}>
    <SEO title={data.markdownRemark.frontmatter.title} />
    <Card variant="white">
      <Wrapper>
        <span dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Wrapper>
    </Card>
  </Layout>
)

export default LegalTemplate

export const query = graphql`
  query legal($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
